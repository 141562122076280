import React from "react";
import Content from '../views/LandingVehicleCare/SignUp';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Kom i gang i dag ved at blive en partner",
    lang: 'da-DK',
    description: "Benyt digitale tjenester helt gratis. Betal kun hvis du vil. Alt inkluderet. Ingen binding. ",
    meta: [{
        name: 'keywords',
        content: 'Kom i gang'
      },
      {
        name: 'robots',
        content: 'noindex',
      }]
  }} />
);