import React from 'react';
import clsx from 'clsx';
import i18n from '../../../../locale';
import Button from '../../../../components/Button';
import styles from './styles.module.sass';

export default ({
  disabled,
  onClick,
  text = i18n('Next')
}) => (
  <div className={styles.container}>
    <Button className={clsx(
        styles.button,
        disabled ? styles.buttonDisabled : ''
      )}
      onClick={disabled
      ? () => {}
      : onClick}>
      {text}
    </Button>
  </div>
);