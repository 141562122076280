import i18n from '../../../locale';
import { landingVc } from '../../../data/sitemap';

export default [{
    name: landingVc._,
    text: i18n('Main'),
    url: i18n('_url:landing-vc')
  },
  {
    name: landingVc.features,
    text: i18n('Features'),
    url: i18n('_url:landing-vc-features')
  },
  {
    name: landingVc.price,
    text: i18n('Price'),
    url: i18n('_url:landing-vc-pricing')
  },
  {
  name: landingVc.signup,
  text: i18n('Get started'),
  url: i18n('_url:landing-vc-signup'),
  callToAction: true 
}];