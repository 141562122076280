import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ text, subtext }) => (
  <Grid container className={styles.container}>
    <Typography variant='h1'
      weight='bold'
      className={styles.text}>
      {text}
    </Typography>
    {subtext
    ? <Typography variant='h5'
        weight='light'
        className={styles.subtext}>
        {subtext}
      </Typography>
    : null}
  </Grid>
)